<template>
  <div class="digital-grow-detail-edit">
    <div class="card">
      <van-row class="title">
        <van-col span="24">
          {{ data.growIndex }}
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="8">
          姓名：{{ data.userName }}
        </van-col>
        <van-col span="16">
          工号：{{ data.userNo }}
        </van-col>
      </van-row>
      <van-row class="info">
        <van-col span="24">转正时间：{{ data.zzDate }}</van-col>
      </van-row>
      <van-row class="info">
        <van-col span="24">转正组办：{{ data.busgrp }}</van-col>
      </van-row>
    </div>
    <div>
      <van-row class="info-title">基本信息</van-row>
      <van-form @submit="editPlan()">
        <van-field @click="clickItem(data.workTerms, '本月关于工作方面新的提升', false)" required type="textarea"
                   label="本月关于工作方面新的提升" placeholder="请填写(不能超过500字)" :rules="digitalGrow.workTerms"
                   readonly
                   maxlength="500"
                   rows="3"
                   v-model="data.workTerms === 'null'?'':data.workTerms"></van-field>
        <van-field required type="textarea" label="" placeholder="员工成长-专业" :rules="digitalGrow.expertise"
                   readonly
                   @click-input="clickItem(data.expertise, '本月关于专业知识方面新的收获', false)"
                   maxlength="500"
                   rows="3"
                   v-model="data.expertise === 'null'?'':data.expertise">
          <template #label>
            <span>本月关于专业知识方面新的收获</span>
            <van-icon @click="clickPoint" size="16px" color="#e59b19" style="margin-left: 5px; " name="warning-o"/>
          </template>
        </van-field>
        <van-row class="make-man">
          <van-col span="14">
            <van-field label="操作人" readonly placeholder="操作人"
                       v-model="data.userName === 'null'?'':data.userName">
            </van-field>
          </van-col>
          <van-col v-if="data.submitTime" class="push-time" span="10">
            提交时间：{{ data.submitTime }}
          </van-col>
        </van-row>
        <van-field @click="clickItem(item.official, '领导评价', readonly)" v-for="(item,index) in data.childOffice" v-bind:key="item.id" :data="item"
                   type="textarea" :label="'领导'+item.leader+':'" placeholder="领导评价" readonly
                   autosize
                   v-model="item.official"></van-field>
        <van-field @click="clickItem(data.official, '领导评价', !readonly)" type="textarea" label="领导评价" placeholder="领导评价"
                   style="height: 150px" readonly
                   rows="3"
                   v-model="data.official"></van-field>

        <van-button :readonly="read" v-if="btn" type="primary" size="large" native-type="submit">保存
        </van-button>
      </van-form>
      <dialog-card :data="DialogData" :fieldValue="fieldValue" :show="show" @success="success" @editVal="editVal"></dialog-card>
    </div>
  </div>
</template>

<script>
import {getDigitalDetailTitle} from '@/api/digital';
import {saveDigitalGrow} from '@/api/digital';
import {Toast} from "vant";
import DialogCard from '../components/DigitalCell/DialogCard';


export default {
  name: "DigitalGrowDetailEdit",
  components: {DialogCard},
  data() {
    return {
      read: false,
      show: false,
      fieldValue: '',
      DialogData: {},
      readonly: true,
      data: {},
      info: {},
      btn: false,
      digitalGrow: {
        workTerms: [
          {required: true, message: '请填写工作方面', trigger: 'blur'},
        ],
        expertise: [
          {required: true, message: '请填写专业知识', trigger: 'blur'},
        ],
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    clickPoint() {
      Toast('专业书籍、概念、术语等');
    },
    editPlan(formName) {
      this.read = true;
      saveDigitalGrow({
        pushId: this.data.pushId,
        workTerms: this.data.workTerms,
        expertise: this.data.expertise,
        growIndex: this.data.growIndex,
        growIndexNew: this.data.growIndex,
        official: this.data.official,
        createdPeople: this.$store.getters.userId,
        userNo: this.$store.getters.userId,
        userName: this.data.userName,
        zzDate: this.data.zzDate,
      }).then(res => {
        this.$router.push({path: "/DigitalGrowDetail/" + this.$store.getters.userId});
      }).catch(err => {
        console.log(err)
      })
    },
    init() {
      getDigitalDetailTitle({pushId: this.$route.params.id}).then(res => {
        console.log(res)
        if (res.length > 0) {
          this.data = res[0]
          //不允许自己领导评价
          if(this.data.userNo == this.$store.getters.userId){
            this.readonly = false
          }
          if (this.data.submitBtn == 'N') {
            this.btn = true;
          }
          if (this.data.leaderNo.split(',').includes(this.$store.getters.userId) || this.$store.getters.userId == '04008' || this.$store.getters.userId == '05001' ) {
            this.btn = true;
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    clickItem(val, text, bool) {
      this.DialogData.text = text
      this.DialogData.value = val
      this.DialogData.edit = bool
      this.fieldValue = val
      this.show = true
    },
    success(res) {
      this.show = res
    },
    editVal(val) {
      console.log(val)
      if (val.text == '领导评价') {
        this.data.official = val.value
      } else if (val.text == '本月关于工作方面新的提升') {
        this.data.workTerms = val.value
      } else if (val.text == '本月关于专业知识方面新的收获') {
        this.data.expertise = val.value
      }
    }
  }
}
</script>

<style scoped>
.digital-grow-detail-edit {
  height: 2100px;
  overflow-y: scroll
}

.digital-grow-detail-edit .title {
  padding: 10px 5px;
  font-weight: 400;
  font-size: 18px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.digital-grow-detail-edit .info {
  text-align: left;
  padding: 10px 5px;
  font-weight: 400;
  font-size: 14px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}

.digital-grow-detail-edit .card {
  background: #318EF5;
}

.digital-grow-detail-edit .info-title {
  padding: 10px 10px 5px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #AAA8A8;
  font-style: normal;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: none;
}

.digital-grow-detail-edit .make-man {
  background-color: #FFFFFF;
}

.digital-grow-detail-edit .push-time {
  text-align: left;
  padding: 10px 5px;
  font-weight: 400;
  font-size: 12px;
  color: #a39e9e;
  font-style: normal;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}
</style>
